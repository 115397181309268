<script setup lang="ts">
const mainStore = await useMainStore()
onBeforeMount(() => {
  mainStore.randomizeTestimonial()
})
const testimonial = computed<any>(() => mainStore.randomTestimonial)
const photo = computed(() => testimonial.value?.photo)
</script>

<template>
  <div class="relative">
    <div class="relative py-16">
      <div class="bg-peach-300 lg:relative lg:z-10 lg:pb-0 lg:max-w-6xl mx-auto">
        <div
          class="lg:mx-auto lg:grid lg:grid-cols-3 lg:gap-8 pb-20 lg:px-8"
        >
          <div class="relative lg:-my-8">
            <div
              aria-hidden="true"
              class="absolute inset-x-0 top-0 w-full h-1/2 bg-white lg:hidden z-0"
            />
            <div class="relative mx-auto max-w-md px-6 sm:max-w-3xl lg:h-full lg:p-0 z-10">
              <div
                class="aspect-w-10 aspect-h-6 overflow-hidden shadow-xl sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full z-10"
              >
                <MediaBaseFigure
                  v-if="photo"
                  :image="photo"
                  :alt="photo.alt ?? ''"
                  :hotspot="photo.hotspot"
                  class="object-cover lg:h-full lg:w-full"
                />
              </div>
            </div>
          </div>
          <div class="mt-12 lg:col-span-2 lg:m-0 lg:pl-8">
            <div
              class="mx-auto max-w-md px-6 sm:max-w-2xl lg:max-w-none lg:px-0 lg:py-20"
            >
              <blockquote>
                <div>
                  <svg
                    class="h-12 w-12 text-primary opacity-25"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path
                      d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
                    />
                  </svg>
                  <div
                    class="mt-6 leading-relaxed text-xl md:text-2xl font-light "
                  >
                    <ElementContent :blocks="testimonial?.quote" />
                  </div>
                </div>
                <footer class="mt-6">
                  <div class="text-base font-medium italic">
                    - {{ testimonial?.name }}
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.leading-relaxed {
  line-height: 1.625 !important;
}
</style>

<script setup lang="ts">
const photoTarget = ref<HTMLElement | null>(null)
const hasPhoto = computed(() => !!(photoTarget.value && photoTarget.value?.children && photoTarget.value?.children?.length))
</script>
<template>
  <div class="relative">
    <div class="relative flex flex-col w-full p-3" :class="{'h-screen': hasPhoto}">
      
      <div class="relative flex-1 flex flex-col w-full overflow-hidden bg-burgundy-200">

        <div class="sticky top-0 z-10">
          <AppHeader />
        </div>
        <div class="flex-1 flex px-2 @container/photo h-full" ref="photoTarget">
          <slot name="photo" />
        </div>

      </div>

    </div>
    <slot></slot>
    <AppFooter />
  </div>
</template>

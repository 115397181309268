<script setup lang="ts">
import { useMainStore } from '~/stores/MainStore'
const scrollTop = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, 61)
}
const route = useRoute()

const mainStore = useMainStore()

const { siteNav, siteSettings, siteOwner } = storeToRefs(mainStore)

const footerLinks = computed<any>(() => mainStore.siteNav?.footer)
const owner = computed(() => mainStore.siteOwner)

// hide footer on specific routes
const hideFooter = computed(() => ["/kontakt"].includes(route.path))
const hideTestimonial = computed(() => ["/anbefalinger"].includes(route.path))
const hideCTA = computed(() => ["/bedriftsfotografering"].includes(route.path))
</script>

<template>
  <div class="p-4">
    <ClientOnly>
      <TestimonialRandom v-if="!hideTestimonial" />
    </ClientOnly>
    <div v-if="!hideCTA">
      <CTA />
    </div>
    <!-- <Instagram v-if="route.path !== '/'" /> -->
    <footer>
      <div class="bg-peach-200">
        <div class="w-full md:max-w-screen-lg grid grid-cols-1 md:grid-cols-3 py-20 mx-auto px-10">
          <div class="text-center md:text-left">
            <div class="ml- text-2xl text-primary-800"> {{ owner?.name }} AS</div>
            <div class="mt-2 text-sm text-neutral-900">Org.no. {{ owner?.orgno }}</div>
            <div class="mt-2 text-sm text-neutral-900 px-5 md:px-0">
              {{ owner?.tagline }}
            </div>
            <div class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start pt-4">
              <SoMe />
            </div>
            <div class="mt-3 text-center md:text-left justify-center bg-accent-3 md:bg-transparent pt-3 pb-4 mb-6">
              <div class="text-xs pb-2">Medlem av</div>
              <div class="">
                <MediaFFLogo width="250" class="mx-auto md:mx-0" />
              </div>
            </div>
          </div>
  
          <div v-for="(group, g) in footerLinks" :key="`footer_group_${g}`" class="md:pl-20">
            <h2 v-if="group.title" class="tracking-widest text-gray-900 text-lg mb-5 text-center md:text-left mt-8 md:mt-0">
              {{ group.title }}
            </h2>
            <nav v-if="group.items" class="list-none mb-12 text-center md:text-left">
              <nuxt-link v-for="(item, s) in group.items" :key="`footer_item_${g}_${s}}`" :to="`/${item.meta.slug}`"
                class="relative block text-neutral-800 duration-200 hover:text-primary md:text-sm mb-3"
                :alt="item.meta.description ?? ''">
                {{ item.meta.title }}
              </nuxt-link>
            </nav>
          </div>
        </div>
      </div>
      <div>
        <div class="md:max-w-screen-lg mx-auto py-4 px-5">
          <div class="flex flex-wrap md:flex-nowrap flex-col sm:flex-row">
            <div class="flex-1 text-neutral-500 text-sm text-center md:text-left">
              <span>
                {{ owner?.footer }}
              </span>
            </div>
            <div class="cursor-pointer text-neutral-900 duration-200 hover:text-primary mx-auto p-6 md:p-0"
              @click="scrollTop">
              <UIcon name="teenyicons:arrow-up-circle-outline" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
const mainStore = useMainStore()
const { cta } = storeToRefs(mainStore)
</script>
<template>
  <div class="relative isolate bg-white px-6 md:px-0">
    <div
      class="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
    >
      <svg
        class="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
        viewBox="0 0 1155 678"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
          fill-opacity=".3"
          d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
        />
        <defs>
          <linearGradient
            id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
            x1="1155.49"
            x2="-78.208"
            y1=".177"
            y2="474.645"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6f7070" />
            <stop
              offset="1"
              stop-color="#8f5f5f"
            />
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div class="flex flex-col justify-center items-center my-16 min-h-[50vh]">
      <div class="text-center max-w-4xl mx-auto">
        <div class="px-0 font-serif font-bold text-5xl md:text-6xl">
          {{ cta?.title }}
        </div>
        <div class="py-12 text-neutral-800 max-w-3xl mx-auto text-2xl">
          {{ cta?.caption }}
        </div>
      </div>
      <div class="flex space-x-4">
        <UButton
          v-for="(btn, b) in cta?.buttons"
          size="xl"
          class="cursor-pointer"
          color="neutral"
          :variant="b > 0 ? 'ghost' : 'solid'"
          :key="`ctadata_button_${b}`"
          :display="btn.display"
          :label="btn.label"
          :internalLink="btn.internalLink"
          :ui="{base: 'px-6 py-3 text-xl'}"
        />
      </div>
    </div>
  </div>
</template>